body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: auto;
  /* works only on webkit browsers */
  overflow-y: overlay;
}

body .ant-modal-footer {
  padding: 0px;
}

body .ant-modal {
  margin-bottom: 4rem;
  padding-bottom: 10rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  height: none;
}
